import { render, staticRenderFns } from "./EmbryoTransferSection.vue?vue&type=template&id=67846a66&scoped=true&"
import script from "./EmbryoTransferSection.vue?vue&type=script&lang=js&"
export * from "./EmbryoTransferSection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67846a66",
  null
  
)

export default component.exports